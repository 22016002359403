@import '../../constants/styling_contstants.module.scss';
@import '@lendoab/aphrodite/dist/tokens';

.container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    z-index: index($zIndexPriorityElements, content);
}

.cardContainer {
    max-width: 448px;
    margin-left: map-get($spacing, medium);
    margin-right: map-get($spacing, medium);

    @media (min-width: map-get($breakpoints, tablet)) {
        max-width: 544px;
        width: 544px;
        margin-left: auto;
        margin-right: auto;
    }
}

.sliderContainer {
    width: 46%;
    height: 100%;
}
