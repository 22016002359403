$easeCurve: cubic-bezier(0.65, -0.01, 0.8, 1.98);
$iconSpeed: 150ms;
$iconScale: 0.5;

.formNotSent {
    .circle {
        stroke: #c7e4d2;
    }
    .cross {
        transform-origin: 50%;
        transform: rotate(45deg) translate(20px, 20px) scale($iconScale);
        opacity: 0;
    }
    .animate {
        .circle {
            animation: border-cross 300ms ease-in-out 200ms forwards;
        }
        .cross {
            animation: show-cross $iconSpeed $easeCurve 400ms forwards;
        }
    }

    @keyframes show-cross {
        from {
            transform: rotate(45deg) scale($iconScale) translate(20px, 20px);
        }
        to {
            transform: rotate(45deg) scale(1) translate(20px, 20px);
            opacity: 1;
        }
    }

    @keyframes border-cross {
        from {
            stroke: #c7e4d2;
        }
        to {
            stroke: #bd5751;
        }
    }
}

@keyframes circle-spinner-container {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}
@keyframes circle-spinner {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
